.cust-title-img {
  transform: scaleX(-1);
  max-width: 155% !important;
  margin-left: -100px;
  /*margin-top: 172px;*/
}

.shadowed {
  -webkit-filter: drop-shadow(12px 12px 25px rgba(0,0,0,0.5));
  filter: url(#drop-shadow);
  -ms-filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=12, OffY=12, Color='#444')";
  filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=12, OffY=12, Color='#444')";
}

/*-----------------------------------------------------------------------------------

    Theme Name: Ashton - One Page Portfolio
    Description: One Page Portfolio Template
    Author: chitrakootweb
    Version: 1.0

-----------------------------------------------------------------------------------*/
/* ----------------------------------

Table of contents
        
    01. Basic
    02. Banner
    03. Navbar
    04. Header
    05. Sidebar
    06. About box
    07. Skill Progress
    08. Timeline
    09. Portfolio
    10. Counter
    11. Testimonials
    12. Contact
    13. Blog
    14. Countdown Coming soon page
    15. Footer
    16. Responsive
    
---------------------------------- */
/*============================== 
    Basic
================================*/
/* loader */
#preloader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  overflow: visible;
  background: #fff;
  display: table;
  text-align: center; }

.loader {
  display: table-cell;
  vertical-align: middle;
  position: relative;
  width: 200px;
  height: 200px; }

.loader-icon {
  width: 80px;
  height: 80px;
  border: 5px solid #232323;
  border-right-color: #eee;
  border-radius: 50%;
  position: relative;
  animation: loader-rotate 1s linear infinite;
  margin: 0 auto; }

@keyframes loader-rotate {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }
.theme-black-color {
  color: #232323; }

.bg-img {
  background-size: cover;
  background-repeat: no-repeat; }

.bg-black-theme {
  background-color: #232323; }

.theme-overlay[data-overlay-dark]:before {
  background: #232323; }

.valign {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

a:hover, a:active {
  color: #232323;
  text-decoration: none; }

.btn {
  display: inline-block;
  font-size: 14px;
  padding: 9px 22px;
  background: #fff;
  border: 2px solid #fff;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 1px !important;
  color: #232323;
  letter-spacing: .5px;
  line-height: inherit;
  width: auto;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out; }
  .btn:hover, .btn:active {
    background: #232323;
    color: #fff;
    border-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 8px !important; }
    .btn:hover span, .btn:active span {
      color: #fff; }
  .btn:focus {
    background: #232323;
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 8px !important;
    border-color: #fff; }
    .btn:focus span {
      color: #fff; }
  .btn span {
    color: #232323; }

.btn.white {
  background: #fff;
  border: 2px solid #232323;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 1px !important;
  color: #232323; }
  .btn.white:hover, .btn.white:active {
    background: #232323;
    color: #fff;
    border-color: #232323; }
    .btn.white:hover span, .btn.white:active span {
      color: #fff; }
  .btn.white:focus {
    background: #232323;
    color: #fff;
    border-color: #232323; }
    .btn.white:focus span {
      color: #fff; }
  .btn.white span {
    color: #232323; }

  .btn.valuator {
    background: #fff;
    border: 2px solid #1ab394;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 1px !important;
    color: #1ab394; }
    .btn.valuator:hover, .btn.valuator:active {
      background: #1ab394;
      color: #fff;
      border-color: #1ab394; }
      .btn.valuator:hover span, .btn.valuator:active span {
        color: #fff; }
    .btn.valuator:focus {
      background: #1ab394;
      color: #fff;
      border-color: #1ab394; }
      .btn.valuator:focus span {
        color: #fff; }
    .btn.valuator span {
      color: #232323; }

.btn.black {
  background: #232323;
  border: 2px solid #232323;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 1px !important;
  color: #fff; }
  .btn.black:hover, .btn.black:active {
    background: #fff;
    color: #232323;
    border-color: #232323; }
    .btn.black:hover span, .btn.black:active span {
      color: #232323; }
  .btn.black:focus {
    background: #fff;
    color: #fff;
    border-color: #232323; }
    .btn.black:focus span {
      color: #232323; }
  .btn.black span {
    color: #fff; }

.section-title {
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 0; }
  .section-title:after {
    content: '';
    width: 60px;
    height: 2px;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    bottom: 0;
    left: calc(50% - 30px); }
  .section-title:before {
    content: '';
    width: 100%;
    height: 1px;
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 250px; }

.section-title.style2:after {
  content: '';
  width: 50px;
  height: 2px;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  bottom: 0;
  left: calc(50% - 30px); }
.section-title.style2:before {
  content: none; }

.section-title.white {
  color: #fff; }
  .section-title.white:after {
    background: rgba(255, 255, 255, 0.6); }
  .section-title.white:before {
    background: rgba(255, 255, 255, 0.2); }

.social-links a {
  display: inline-block;
  margin: 0 12px;
  font-size: 18px;
  color: #939393; }
  .social-links a:hover {
    color: #232323; }
  .social-links a:first-child {
    margin-left: 0; }
  .social-links a:last-child {
    margin-right: 0; }

.hover-white a:hover {
  color: #fff; }

/*============================== 
    Banner
================================*/
.banner-headline {
  font-size: 3rem;
  line-height: 1.2; }

.banner-headline.medium {
  font-size: 1.6rem; }

@media only screen and (min-width: 768px) {
  .banner-headline {
    font-size: 4.4rem;
    font-weight: 300; } }
@media only screen and (min-width: 1170px) {
  .banner-headline {
    font-size: 6rem; }

  .banner-headline.medium {
    font-size: 5rem;
    line-height: 1.2; } }
.creative {
  min-height: 100vh; }

.svg {
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  z-index: 2; }
  .svg svg {
    fill: #fff;
    width: 100%; }
  .svg.gray svg {
    fill: #f7f7f7;
    fill: #f7f7f7; }
  .svg.extra-md-gray svg {
    fill: #3a3a3a; }
  .svg.dark-gray svg {
    fill: #252525; }

.banner-words-wrapper {
  display: inline-block;
  position: relative;
  text-align: left; }
  .banner-words-wrapper b {
    display: inline-block;
    position: absolute;
    white-space: nowrap;
    left: 0;
    top: 0; }
    .banner-words-wrapper b.is-visible {
      position: relative; }

.no-js .banner-words-wrapper b {
  opacity: 0; }
  .no-js .banner-words-wrapper b.is-visible {
    opacity: 1; }

/* xclip */
.banner-headline.clip span {
  display: inline-block;
  padding: .2em 0; }

.banner-headline.clip .banner-words-wrapper {
  overflow: hidden;
  vertical-align: top; }
  .banner-headline.clip .banner-words-wrapper:after {
    content: '';
    position: absolute;
    top: 10%;
    right: 0;
    width: 2px;
    height: 70%;
    background-color: rgba(255, 255, 255, 0.65); }
.banner-headline.clip b {
  opacity: 0; }
.banner-headline b.is-visible {
  opacity: 1; }

/* Owl theme control */
.owl-theme .owl-dots .owl-dot span {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #d5d5d5;
  margin: 5px 4px 0 4px;
  border: 2px solid #d5d5d5; }
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #232323;
  border-color: #232323; }

/* background video */
.bg-vid {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%; }

/* particles banner */
#particles-js {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; }

/*============================== 
    Navbar
================================*/
.navbar {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background: transparent;
  z-index: 9;
  min-height: 70px;
  padding: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15); }
  .navbar .icon-bar {
    color: #fff; }
  .navbar.bg-white .icon-bar {
    color: #232323; }
  .navbar .navbar-nav .nav-link {
    font-size: 13px;
    font-weight: 600;
    color: #dadada;
    letter-spacing: .5px;
    margin: 0;
    padding: 10px 15px;
    transition: all .5s;
    text-align: left;
    text-transform: uppercase; }
    .navbar .navbar-nav .nav-link:hover {
      color: #fff; }
    .navbar .navbar-nav .nav-link.active {
      color: #fff;
      position: relative; }
  .navbar.nav-scroll {
    background: #fff;
    box-shadow: 0 5px 10px -10px rgba(0, 0, 0, 0.6);
    position: fixed;
    top: -100px;
    left: 0;
    width: 100%;
    transition: transform .5s;
    transform: translateY(100px); }
    .navbar.nav-scroll.bg-black .icon-bar {
      color: #fff; }
    .navbar.nav-scroll .icon-bar {
      color: #232323; }
    .navbar.nav-scroll .navbar-nav .nav-link {
      color: #828282; }
      .navbar.nav-scroll .navbar-nav .nav-link:hover, .navbar.nav-scroll .navbar-nav .nav-link.active {
        color: #232323; }
    .navbar.nav-scroll .navbar-nav .logo {
      padding: 15px 0; }
  .navbar.bg-black {
    background: #232323; }
    .navbar.bg-black .navbar-nav .nav-link {
      color: #a0a0a0; }
      .navbar.bg-black .navbar-nav .nav-link.active {
        color: #fff; }
    .navbar.bg-black.nav-scroll .navbar-nav .nav-link {
      color: #a0a0a0; }
    .navbar.bg-black .navbar-nav .nav-link:hover, .navbar.bg-black.nav-scroll .navbar-nav .nav-link.active {
      color: #fff; }
    .navbar.bg-black .sidemenu_btn > span {
      background: #fff; }
    .navbar.bg-black.nav-scroll {
      background: #000; }
  .navbar.bg-black-scroll.nav-scroll .navbar-nav .nav-link {
    color: #a0a0a0; }
    .navbar.bg-black-scroll.nav-scroll .navbar-nav .nav-link.active {
      color: #fff; }
  .navbar.bg-black-scroll .sidemenu_btn > span {
    background: #fff; }
  .navbar.bg-black-scroll.nav-scroll {
    background: #000; }
  .navbar.bg-light-gray {
    background: #f7f7f7; }
    .navbar.bg-light-gray .navbar-nav .nav-link {
      color: #828282; }
      .navbar.bg-light-gray .navbar-nav .nav-link.active {
        color: #232323; }
    .navbar.bg-light-gray.nav-scroll .navbar-nav .nav-link {
      color: #828282; }
    .navbar.bg-light-gray .navbar-nav .nav-link:hover, .navbar.bg-light-gray.nav-scroll .navbar-nav .nav-link.active {
      color: #232323; }
    .navbar.bg-light-gray .sidemenu_btn > span {
      background: #232323; }
    .navbar.bg-light-gray.nav-scroll {
      background: #fff; }
  .navbar.bg-white {
    background: #fff; }
    .navbar.bg-white .navbar-nav .nav-link {
      color: #232323; }
      .navbar.bg-white .navbar-nav .nav-link.active {
        color: #fff; }
    .navbar.bg-white.nav-scroll .navbar-nav .nav-link {
      color: #232323; }
    .navbar.bg-white .navbar-nav .nav-link:hover, .navbar.bg-white.nav-scroll .navbar-nav .nav-link.active {
      color: #fff; }

.logo {
  padding: 15px 0;
  width: 125px; }

/*============================== 
    Header
================================*/
.header {
  overflow: hidden; }
  .header .caption .overflow-hidden {
    display: inline-block; }
  .header .caption h3 {
    font-weight: 200;
    animation-delay: 0.5s;
    position: relative;
    display: inline-block; }
  .header .caption h1 {
    margin: 10px 0;
    font-size: 70px;
    font-weight: 500;
    animation-delay: 0.8s; }
  .header .caption p {
    font-size: 18px;
    color: #eee;
    word-spacing: 2px;
    animation-delay: 1.2s; }
  .header .btn {
    animation-delay: 1.4s; }
    .header .btn i {
      position: relative;
      top: 1px; }
  .header .bg-dark-gray .social-links a:hover {
    color: #232323; }

/*============================== 
    Sidebar
================================*/
.side-menu {
  width: 30%;
  position: fixed;
  right: 0;
  top: 0;
  background: #fff;
  z-index: 1032;
  height: 100%;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  -webkit-transition: -webkit-transform .5s ease;
  transition: -webkit-transform .5s ease;
  -o-transition: -o-transform .5s ease;
  transition: transform .5s ease;
  transition: transform .5s ease, -webkit-transform .5s ease, -o-transform .5s ease;
  transition: transform .5s ease, -webkit-transform .5s ease;
  overflow: hidden; }
  .side-menu.left {
    left: 0;
    right: auto;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  .side-menu.side-menu-active, .side-menu.before-side {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  .side-menu .navbar-brand {
    margin: 0 0 2.5rem 0; }

/*Side overlay*/
#close_sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  -webkit-transition: opacity 300ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
  -o-transition: opacity 300ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: opacity 300ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
  display: none;
  z-index: 1031;
  opacity: 0.4; }

/*side close btn*/
.side-menu .btn-close {
  height: 33px;
  width: 33px;
  display: inline-block;
  text-align: center;
  position: absolute;
  top: 30px;
  right: 15px;
  cursor: pointer; }
.side-menu.before-side .btn-close {
  display: none; }
.side-menu .btn-close:before, .side-menu .btn-close:after {
  position: absolute;
  left: 16px;
  content: ' ';
  height: 24px;
  width: 2px;
  background: #232323;
  top: 5px; }
.side-menu .btn-close:before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg); }
.side-menu .btn-close:after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg); }

/*side open btn*/
.sidemenu_btn {
  cursor: pointer;
  -webkit-transition: all .3s linear;
  -o-transition: all .3s linear;
  transition: all .3s linear;
  width: 36px;
  padding: 6px;
  margin-right: 1rem;
  position: absolute;
  right: 25px;
  top: 22px;
  z-index: 99; }
  .sidemenu_btn.left {
    left: 15px;
    right: auto; }
  .sidemenu_btn > span {
    height: 2px;
    width: 100%;
    background: #fff;
    display: block; }
    .sidemenu_btn > span:nth-child(2) {
      margin: 4px 0; }

.nav-scroll .sidemenu_btn > span {
  background: #232323; }

.bg-black.nav-scroll .sidemenu_btn > span {
  background: #fff; }

.bg-light-gray.nav-scroll .sidemenu_btn > span {
  background: #232323; }

.bg-black .sidemenu_btn > span {
  background: #fff; }

.side-menu .inner-wrapper {
  padding: 3.5rem 5rem;
  height: 100%;
  position: relative;
  overflow-y: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
.side-menu .side-nav {
  margin-bottom: 30px;
  display: block; }

.side-nav .navbar-nav .nav-item {
  display: block;
  margin: 10px 0;
  padding: 0 !important;
  opacity: 0;
  -webkit-transition: all 0.8s ease 500ms;
  -o-transition: all 0.8s ease 500ms;
  transition: all 0.8s ease 500ms;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  -o-transform: translateY(30px);
  transform: translateY(30px); }
  .side-nav .navbar-nav .nav-item:first-child {
    -webkit-transition-delay: .1s;
    -o-transition-delay: .1s;
    transition-delay: .1s; }
  .side-nav .navbar-nav .nav-item:nth-child(2) {
    -webkit-transition-delay: .2s;
    -o-transition-delay: .2s;
    transition-delay: .2s; }
  .side-nav .navbar-nav .nav-item:nth-child(3) {
    -webkit-transition-delay: .3s;
    -o-transition-delay: .3s;
    transition-delay: .3s; }
  .side-nav .navbar-nav .nav-item:nth-child(4) {
    -webkit-transition-delay: .4s;
    -o-transition-delay: .4s;
    transition-delay: .4s; }
  .side-nav .navbar-nav .nav-item:nth-child(5) {
    -webkit-transition-delay: .5s;
    -o-transition-delay: .5s;
    transition-delay: .5s; }
  .side-nav .navbar-nav .nav-item:nth-child(6) {
    -webkit-transition-delay: .6s;
    -o-transition-delay: .6s;
    transition-delay: .6s; }
  .side-nav .navbar-nav .nav-item:nth-child(7) {
    -webkit-transition-delay: .7s;
    -o-transition-delay: .7s;
    transition-delay: .7s; }
  .side-nav .navbar-nav .nav-item:nth-child(8) {
    -webkit-transition-delay: .8s;
    -o-transition-delay: .8s;
    transition-delay: .8s; }
  .side-nav .navbar-nav .nav-item:nth-child(9) {
    -webkit-transition-delay: .9s;
    -o-transition-delay: .9s;
    transition-delay: .9s; }

.side-menu.side-menu-active .side-nav .navbar-nav .nav-item {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  opacity: 1; }

.side-nav .navbar-nav .nav-link {
  display: inline-table;
  color: #232323;
  padding: 2px 0 3px 0 !important;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: normal;
  position: relative;
  -webkit-border-radius: 0;
  border-radius: 0; }
  .side-nav .navbar-nav .nav-link:after {
    content: "";
    position: absolute;
    background: #232323;
    display: inline-block;
    width: 0;
    height: 1px;
    bottom: 0;
    left: 0;
    overflow: hidden;
    -webkit-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
    -o-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
    transition: all 0.5s cubic-bezier(0, 0, 0.2, 1); }
  .side-nav .navbar-nav .nav-link:hover:after, .side-nav .navbar-nav .nav-link:focus:after {
    width: 100%; }
  .side-nav .navbar-nav .nav-link.active {
    background: transparent; }
    .side-nav .navbar-nav .nav-link.active:after {
      width: 100%; }

.side-menu .social-links a {
  color: #939393; }
  .side-menu .social-links a:hover {
    color: #232323; }
.side-menu.black {
  background: rgba(0, 0, 0, 0.95); }
  .side-menu.black .btn-close:before, .side-menu.black .btn-close:after {
    background: #fff; }
  .side-menu.black .side-nav .navbar-nav .nav-link {
    color: #fff; }
    .side-menu.black .side-nav .navbar-nav .nav-link:after {
      background: #fff; }
    .side-menu.black .side-nav .navbar-nav .nav-link.active {
      background: transparent; }
  .side-menu.black .social-links a:hover {
    color: #fff; }

/* Side Left Panel*/
.side-menu.left-panel {
  display: none; }

@media screen and (min-width: 992px) {
  /* Side Left Panel*/
  .side-menu.left-panel {
    width: 250px;
    left: 0;
    right: auto;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    display: block; }
    .side-menu.left-panel .side-nav {
      margin-bottom: 15px; }
    .side-menu.left-panel .inner-wrapper {
      padding: 2rem;
      overflow-x: hidden; }
    .side-menu.left-panel .profile-pic {
      float: left;
      width: 100%;
      text-align: left; }
    .side-menu.left-panel .profile-list {
      margin: 0 0 10px 0; }
      .side-menu.left-panel .profile-list li {
        margin-bottom: 5px; }

  .left-panel .side-nav .navbar-nav .nav-link {
    font-size: 1rem; }
  .left-panel .side-nav .navbar-nav .nav-item {
    margin: 10px 0 0 0; }
    .left-panel .side-nav .navbar-nav .nav-item:first-child {
      margin-top: 0; }
  .left-panel .social-links a {
    margin: 0 10px;
    font-size: 16px; }
    .left-panel .social-links a:first-child {
      margin-left: 0; }
    .left-panel .social-links a:last-child {
      margin-right: 0; }

  /* Side Dark Left Panel*/
  .left-panel.dark {
    background: #3a3a3a;
    border-color: rgba(255, 255, 255, 0.1); }

  .left-panel.dark .side-nav .navbar-nav .nav-link {
    color: #d6d5d5; }

  .left-panel.dark .side-nav .navbar-nav .nav-link:after {
    background: #d6d5d5; }

  .sidebar-wrapper {
    padding-left: 250px; } }
/*============================== 
    About box
================================*/
.about-box {
  border-radius: 5px;
  -webkit-box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2) inset;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2) inset;
  display: block;
  margin-right: 0;
  margin-top: 0;
  position: relative; }

.bg-extra-medium-gray .about-box, .bg-dark-gray .about-box {
  -webkit-box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2) inset;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2) inset; }

.porfile-photo {
  position: relative;
  -webkit-transform: translateY(24px) translateX(24px);
  transform: translateY(24px) translateX(24px); }
  .porfile-photo > img {
    border-radius: 5px; }

@media only screen and (max-width: 767px) {
  .porfile-photo {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0); } }
/*============================== 
    Skill Progress
================================*/
.skills .prog-item {
  margin-bottom: 25px; }
  .skills .prog-item:last-child {
    margin-bottom: 0; }
  .skills .prog-item p {
    font-size: 14px;
    margin-bottom: 8px; }
  .skills .prog-item .skills-progress {
    width: 100%;
    height: 5px;
    background: #eee;
    border-radius: 5px;
    position: relative; }
    .skills .prog-item .skills-progress span {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      background: #474747;
      width: 10%;
      border-radius: 10px;
      transition: all 1s; }
      .skills .prog-item .skills-progress span:after {
        content: attr(data-value);
        position: absolute;
        top: -20px;
        right: 0;
        font-size: 10px;
        color: #777; }

.bg-extra-medium-gray .skills .prog-item .skills-progress, .bg-dark-gray .skills .prog-item .skills-progress {
  background: #000; }
  .bg-extra-medium-gray .skills .prog-item .skills-progress span, .bg-dark-gray .skills .prog-item .skills-progress span {
    background: #e6e6e6; }
    .bg-extra-medium-gray .skills .prog-item .skills-progress span:after, .bg-dark-gray .skills .prog-item .skills-progress span:after {
      color: #fff; }

/*============================== 
    Timeline
================================*/
.main-timeline {
  position: relative; }
  .main-timeline:before {
    content: "";
    display: block;
    width: 2px;
    height: 100%;
    background: #c6c6c6;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0; }
  .main-timeline .timeline {
    margin-bottom: 40px;
    position: relative; }
    .main-timeline .timeline:after {
      content: "";
      display: block;
      clear: both; }
  .main-timeline .icon {
    width: 18px;
    height: 18px;
    line-height: 18px;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; }
    .main-timeline .icon:before, .main-timeline .icon:after {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      transition: all 0.33s ease-out 0s; }
    .main-timeline .icon:before {
      background: #fff;
      border: 2px solid #232323;
      left: -3px; }
    .main-timeline .icon:after {
      border: 2px solid #c6c6c6;
      left: 3px; }
  .main-timeline .timeline:hover .icon:before {
    left: 3px; }
  .main-timeline .timeline:hover .icon:after {
    left: -3px; }
  .main-timeline .date-content {
    width: 50%;
    float: left;
    margin-top: 22px;
    position: relative; }
    .main-timeline .date-content:before {
      content: "";
      width: 36.5%;
      height: 2px;
      background: #c6c6c6;
      margin: auto 0;
      position: absolute;
      top: 0;
      right: 10px;
      bottom: 0; }
  .main-timeline .date-outer {
    width: 125px;
    height: 125px;
    font-size: 16px;
    text-align: center;
    margin: auto;
    z-index: 1; }
    .main-timeline .date-outer:before, .main-timeline .date-outer:after {
      content: "";
      width: 125px;
      height: 125px;
      margin: 0 auto;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      transition: all 0.33s ease-out 0s; }
    .main-timeline .date-outer:before {
      background: #fff;
      border: 2px solid #232323;
      left: -6px; }
    .main-timeline .date-outer:after {
      border: 2px solid #c6c6c6;
      left: 6px; }
  .main-timeline .timeline:hover .date-outer:before {
    left: 6px; }
  .main-timeline .timeline:hover .date-outer:after {
    left: -6px; }
  .main-timeline .date {
    width: 100%;
    margin: auto;
    position: absolute;
    top: 27%;
    left: 0; }
  .main-timeline .month {
    font-size: 18px;
    font-weight: 700; }
  .main-timeline .year {
    display: block;
    font-size: 30px;
    font-weight: 700;
    color: #232323;
    line-height: 36px; }
  .main-timeline .timeline-content {
    width: 50%;
    padding: 20px 0 20px 50px;
    float: right; }
  .main-timeline .title {
    font-size: 19px;
    font-weight: 700;
    line-height: 24px;
    margin: 0 0 15px 0; }
  .main-timeline .description {
    margin-bottom: 0; }
  .main-timeline .timeline:nth-child(2n) .date-content {
    float: right; }
    .main-timeline .timeline:nth-child(2n) .date-content:before {
      left: 10px; }
  .main-timeline .timeline:nth-child(2n) .timeline-content {
    padding: 20px 50px 20px 0;
    text-align: right; }

@media only screen and (max-width: 991px) {
  .main-timeline .date-content {
    margin-top: 35px; }
    .main-timeline .date-content:before {
      width: 22.5%; }
  .main-timeline .timeline-content {
    padding: 10px 0 10px 30px; }
  .main-timeline .title {
    font-size: 17px; }
  .main-timeline .timeline:nth-child(2n) .timeline-content {
    padding: 10px 30px 10px 0; } }
@media only screen and (max-width: 767px) {
  .main-timeline:before {
    margin: 0;
    left: 7px; }
  .main-timeline .timeline {
    margin-bottom: 20px; }
    .main-timeline .timeline:last-child {
      margin-bottom: 0; }
  .main-timeline .icon {
    margin: auto 0; }
  .main-timeline .date-content {
    width: 95%;
    float: right;
    margin-top: 0; }
    .main-timeline .date-content:before {
      display: none; }
  .main-timeline .date-outer {
    width: 110px;
    height: 110px; }
    .main-timeline .date-outer:before, .main-timeline .date-outer:after {
      width: 110px;
      height: 110px; }
  .main-timeline .date {
    top: 30%; }
  .main-timeline .year {
    font-size: 24px; }
  .main-timeline .timeline-content, .main-timeline .timeline:nth-child(2n) .timeline-content {
    width: 95%;
    text-align: center;
    padding: 10px 0; }
  .main-timeline .title {
    margin-bottom: 10px; } }
/*============================== 
    Portfolio
================================*/
.portfolio {
  overflow: hidden; }
  .portfolio .filtering span {
    margin-right: 8px;
    color: #333;
    letter-spacing: 1px;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid #d5d5d5;
    border-radius: 30px;
    padding: 5px 20px;
    display: inline-block;
    margin-bottom: 50px; }
    .portfolio .filtering span:last-child {
      margin: 0; }
    .portfolio .filtering span:hover {
      border-color: #232323;
      background: #232323;
      color: #fff; }
  .portfolio .filtering .active {
    border-color: #232323;
    background: #232323;
    color: #fff; }
  .portfolio .items {
    margin-top: 30px; }
  .portfolio .item-img {
    position: relative;
    overflow: hidden; }
    .portfolio .item-img img {
      width: 100%; }
    .portfolio .item-img:hover .item-img-overlay {
      opacity: 1;
      transform: translateY(0); }
    .portfolio .item-img:hover h6 {
      transform: translateY(0); }
    .portfolio .item-img h6 {
      font-weight: 600;
      position: relative;
      font-size: 16px;
      margin-bottom: 0;
      text-transform: capitalize;
      transform: translateY(15px);
      transition: all .5s; }
    .portfolio .item-img .fa {
      background: #232323;
      border: 1px solid #232323;
      border-radius: 50%;
      color: #fff;
      display: inline-block;
      height: 30px;
      line-height: 28px;
      width: 30px;
      margin-bottom: 5px;
      transition: all .5s; }
      .portfolio .item-img .fa:hover {
        background: #fff;
        color: #232323; }
  .portfolio .item-img-overlay {
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    background: rgba(255, 255, 255, 0.95);
    color: #35424C;
    opacity: 0;
    transition: all .5s;
    z-index: 2;
    transform: translateY(10px); }

.bg-extra-medium-gray.portfolio .filtering span {
  color: #fff; }

/*============================== 
    Counter
================================*/
.numbers .item .icon {
  font-size: 36px;
  display: inline-block;
  color: #232323; }
.numbers .item h6 {
  font-weight: 500; }
.numbers .item p {
  font-size: 16px;
  letter-spacing: 1px;
  color: #232323;
  margin-bottom: 0; }

/*============================== 
    Testimonials
================================*/
.testimonials h4 {
  font-size: 16px;
  margin: 0;
  line-height: normal;
  font-weight: 600; }
.testimonials p {
  margin-bottom: 0;
  line-height: 28px; }
.testimonials h6 {
  font-size: 12px;
  margin: 0; }
.testimonials .owl-theme .owl-dots {
  margin-top: 40px !important; }
  .testimonials .owl-theme .owl-dots .owl-dot span {
    border-color: 1px solid #fff;
    width: 10px;
    height: 10px; }

@media screen and (max-width: 991px) {
  .testimonials .owl-theme .owl-dots {
    margin-top: 30px !important; } }
@media screen and (max-width: 767px) {
  .testimonials .owl-theme .owl-dots {
    margin-top: 20px !important; } }
/*============================== 
    Contact
================================*/
.contact .map {
  padding: 0;
  position: relative;
  z-index: 4;
  height: 400px; }
.contact .info .item:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border: none; }
.contact .info .item .icon {
  text-align: center; }
.contact .info .item .cont h6 {
  font-size: 18px;
  margin-bottom: 5px;
  line-height: normal; }
.contact .info .item .cont p {
  margin: 0; }

/*============================== 
    Blog
================================*/
.blog .item {
  box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15);
  background: #fff;
  height: 100%; }
  .blog .item .post-img {
    position: relative;
    overflow: hidden; }
    .blog .item .post-img:hover img {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%); }
    .blog .item .post-img img {
      width: 100%;
      -webkit-filter: none;
      filter: none;
      transition: 0.3s; }
  .blog .item .content {
    padding: 30px;
    text-align: left; }
    .blog .item .content .tag {
      font-weight: 500;
      font-size: 12px;
      margin-bottom: 5px;
      letter-spacing: 1px; }
    .blog .item .content .read-more {
      margin-top: 20px;
      color: #232323;
      font-weight: 600;
      cursor: pointer; }
      .blog .item .content .read-more:hover {
        text-decoration: underline; }
    .blog .item .content p {
      margin-bottom: 10px;
      line-height: normal; }
    .blog .item .content h5 {
      font-size: 16px;
      line-height: normal;
      text-transform: inherit; }
      .blog .item .content h5 a {
        color: #232323; }

.blog-nav {
  position: static !important;
  background: #fff !important;
  margin: 0;
  top: 0 !important;
  transform: translateY(0px) !important; }
  .blog-nav .logo {
    color: #1c1c1c !important; }
  .blog-nav .navbar-nav .nav-link {
    color: #828282; }
    .blog-nav .navbar-nav .nav-link:hover {
      color: #232323; }
  .blog-nav.navbar .icon-bar {
    color: #232323; }

.blog-header {
  background: #232323;
  position: relative; }
  .blog-header h5 {
    font-weight: 700;
    letter-spacing: 1px;
    color: #d6d5d5;
    margin-bottom: 15px; }
  .blog-header a {
    position: relative;
    color: #d5d5d5; }
    .blog-header a:after {
      content: '/';
      position: relative;
      margin: 0 10px; }
    .blog-header a:last-child:after {
      display: none; }
    .blog-header a:hover {
      color: #fff; }
    .blog-header a.active {
      color: #fff; }

.blogs .posts {
  /* pagination */ }
  .blogs .posts .post {
    margin-bottom: 30px; }
    .blogs .posts .post .content {
      padding: 30px;
      background: #fafafa; }
      .blogs .posts .post .content .post-title h5 {
        font-size: 26px;
        line-height: normal;
        font-weight: 500;
        margin-bottom: 0; }
        .blogs .posts .post .content .post-title h5 a {
          color: #232323; }
          .blogs .posts .post .content .post-title h5 a:hover {
            color: #232323; }
      .blogs .posts .post .content .meta {
        margin-bottom: 15px; }
        .blogs .posts .post .content .meta li {
          display: inline-block;
          font-size: 12px;
          color: #777;
          margin: 5px; }
      .blogs .posts .post .content .spical {
        padding: 15px;
        margin: 30px 0;
        border-left: 2px solid #111;
        background: #f7f7f7;
        font-size: 16px; }
    .blogs .posts .post .share-post {
      margin-top: 30px;
      padding-top: 20px;
      border-top: 1px dashed #eee;
      text-align: left; }
      .blogs .posts .post .share-post span {
        font-weight: 700; }
      .blogs .posts .post .share-post ul {
        float: right; }
        .blogs .posts .post .share-post ul li {
          display: inline-block;
          margin: 0 10px; }
    .blogs .posts .post .post-img img {
      width: 100%; }
  .blogs .posts .title-g h3 {
    font-weight: 700;
    font-size: 25px; }
  .blogs .posts .comments-area .comment-box {
    padding-bottom: 30px;
    margin-bottom: 50px;
    border-bottom: 1px solid #eee; }
    .blogs .posts .comments-area .comment-box:nth-child(odd) {
      margin-left: 80px; }
    .blogs .posts .comments-area .comment-box:last-child {
      margin-bottom: 30px; }
    .blogs .posts .comments-area .comment-box .author-thumb {
      width: 80px;
      float: left; }
    .blogs .posts .comments-area .comment-box .comment-info {
      margin-left: 100px; }
      .blogs .posts .comments-area .comment-box .comment-info h6 {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 10px; }
      .blogs .posts .comments-area .comment-box .comment-info .reply {
        margin-top: 10px;
        font-weight: 600; }
        .blogs .posts .comments-area .comment-box .comment-info .reply i {
          padding-right: 5px;
          font-size: 12px; }
  .blogs .posts .pagination {
    border-radius: 0;
    padding: 0;
    margin: 0; }
    .blogs .posts .pagination ul {
      display: inline-block;
      *display: inline;
      *zoom: 1;
      margin: 0 auto;
      padding: 0; }
    .blogs .posts .pagination li {
      display: inline; }
    .blogs .posts .pagination a {
      float: left;
      padding: 0 18px;
      line-height: 40px;
      text-decoration: none;
      border: 1px solid #dbdbdb;
      border-left-width: 0;
      background: #fff; }
      .blogs .posts .pagination a:hover {
        background-color: #232323;
        color: #fff; }
    .blogs .posts .pagination .active a {
      background-color: #f7f7f7;
      color: #999;
      cursor: default; }
    .blogs .posts .pagination .disabled span {
      color: #999;
      background-color: transparent;
      cursor: default; }
    .blogs .posts .pagination .disabled a {
      color: #999;
      background-color: transparent;
      cursor: default; }
      .blogs .posts .pagination .disabled a:hover {
        color: #999;
        background-color: transparent;
        cursor: default; }
    .blogs .posts .pagination li:first-child a {
      border-left-width: 1px; }
.blogs .side-bar .widget {
  margin-bottom: 30px; }
  .blogs .side-bar .widget:last-child {
    margin-bottom: 0; }
  .blogs .side-bar .widget .widget-title h6 {
    position: relative;
    padding-bottom: 5px;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 20px; }
    .blogs .side-bar .widget .widget-title h6:after {
      content: '';
      width: 30px;
      height: 1px;
      background: #232323;
      position: absolute;
      bottom: 0;
      left: 0; }
  .blogs .side-bar .widget li {
    position: relative;
    padding: 0 0 0 12px;
    margin: 0 0 9px 0;
    color: #6f6f6f; }
    .blogs .side-bar .widget li:last-child {
      margin: 0; }
    .blogs .side-bar .widget li:after {
      content: "";
      width: 5px;
      height: 1px;
      background-color: #6f6f6f;
      position: absolute;
      top: 12px;
      left: 0; }
  .blogs .side-bar .widget .social-listing {
    margin-bottom: 0; }
    .blogs .side-bar .widget .social-listing li {
      list-style: none;
      display: inline-block; }
      .blogs .side-bar .widget .social-listing li:after {
        background: none; }
      .blogs .side-bar .widget .social-listing li:first-child {
        padding-left: 0; }
.blogs .side-bar .search form input {
  width: calc(100% - 50px);
  height: 50px;
  padding: 0 10px;
  margin: 0;
  border: 1px solid #d1d1d1;
  background: #f7f7f7; }
.blogs .side-bar .search form button {
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: #1c1c1c;
  color: #fff;
  border: 0;
  float: right; }

/*============================== 
    Countdown Coming soon page
================================*/
.coming-soon .social-links li {
  display: inline-block;
  font-size: 18px;
  margin-right: 30px; }
  .coming-soon .social-links li:last-child {
    margin-right: 0; }
  .coming-soon .social-links li a:hover {
    color: #fff; }

ul.countdown li {
  display: inline-block;
  padding: 0 30px;
  text-align: center;
  border-right: 1px solid #626262; }
  ul.countdown li:last-child {
    border: none;
    padding-right: 0; }
  ul.countdown li span {
    font-size: 36px;
    font-weight: 700;
    line-height: normal;
    position: relative; }
    ul.countdown li span:before {
      content: "";
      height: 1px;
      position: absolute;
      width: 100%; }
  ul.countdown li p.timeRefDays, ul.countdown li p.timeRefHours, ul.countdown li p.timeRefMinutes, ul.countdown li p.timeRefSeconds {
    font-size: 12px;
    margin: 0;
    padding: 0;
    text-transform: uppercase; }

/*============================== 
    Footer
================================*/
.footer-top {
  padding: 100px 0px; }

.footer-bottom {
  padding: 25px 0px; }
  .footer-bottom a:hover {
    color: #000; }

.footer-social a {
  display: inline-block;
  margin: 0 25px; }
  .footer-social a:first-child {
    margin-left: 0; }
  .footer-social a i {
    color: #939393;
    font-size: 22px; }
  .footer-social a:hover i {
    color: #fff; }

.back-to-top {
  background: #6f6f6f;
  border-radius: 50%;
  position: fixed;
  z-index: 1000;
  right: 25px;
  bottom: 15px;
  width: 35px;
  height: 35px;
  margin: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: opacity 350ms;
  -o-transition: opacity 350ms;
  transition: opacity 350ms; }
  .back-to-top i {
    position: absolute;
    left: 50%;
    top: 45%;
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 16px; }
  .back-to-top:hover {
    background: #232323 !important; }
  .back-to-top.reveal {
    cursor: pointer;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    opacity: 1;
    filter: alpha(opacity=100); }
    .back-to-top.reveal:focus, .back-to-top.reveal:hover, .back-to-top.reveal:active {
      opacity: 1;
      filter: alpha(opacity=100); }

/*============================== 
    Responsive
================================*/
@media screen and (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse.desktop-hide {
    display: none !important; }

  .sidebar-nav .sidemenu_btn {
    top: 15px;
    right: 10px;
    margin-right: 0; }

  .desktop-hide {
    display: none !important; } }
@media screen and (max-width: 1399px) {
  .navbar > .container {
    padding-right: 3.5rem; }

  .sidemenu_btn {
    margin-right: 0; } }
@media screen and (max-width: 1199px) {
  .footer-top {
    padding: 70px 0; } }
@media screen and (max-width: 991px) {
  .navbar > .container {
    padding-right: 0; }
  .navbar .icon-bar {
    color: #fff; }
  .navbar.bg-white .icon-bar {
    color: #232323; }
  .navbar .navbar-nav {
    background: #f7f7f7; }
    .navbar .navbar-nav .nav-link {
      color: #828282;
      padding: 8px 15px; }
      .navbar .navbar-nav .nav-link:hover, .navbar .navbar-nav .nav-link.active {
        color: #232323; }

  .nav-scroll .navbar-nav {
    background: #f7f7f7; }
    .nav-scroll .navbar-nav .nav-link {
      color: #232323 !important;
      opacity: 0.65; }
    .nav-scroll .navbar-nav .active {
      color: #232323 !important;
      opacity: 1; }

  /*header bg-black-theme */
  .navbar.bg-black {
    background: #232323; }
    .navbar.bg-black .icon-bar {
      color: #fff; }
    .navbar.bg-black .navbar-nav .nav-link {
      color: #232323;
      opacity: 0.65; }
      .navbar.bg-black .navbar-nav .nav-link.active {
        color: #232323;
        opacity: 1; }
    .navbar.bg-black.nav-scroll .navbar-nav .nav-link {
      color: #232323;
      opacity: 0.65; }
    .navbar.bg-black .navbar-nav .nav-link:hover, .navbar.bg-black.nav-scroll .navbar-nav .nav-link.active {
      color: #232323;
      opacity: 1; }
  .navbar.bg-black-scroll .icon-bar {
    color: #fff; }
  .navbar.bg-black-scroll.nav-scroll .navbar-nav .nav-link {
    color: #232323;
    opacity: 0.65; }
  .navbar.bg-black-scroll .navbar-nav .nav-link:hover, .navbar.bg-black-scroll.nav-scroll .navbar-nav .nav-link.active {
    color: #232323;
    opacity: 1; }
  .navbar.bg-light-gray {
    background: #f7f7f7; }
    .navbar.bg-light-gray .navbar-nav {
      background: #fff; }
    .navbar.bg-light-gray .icon-bar {
      color: #232323; }
    .navbar.bg-light-gray .navbar-nav .nav-link {
      color: #232323;
      opacity: 0.65; }
      .navbar.bg-light-gray .navbar-nav .nav-link.active {
        color: #232323;
        opacity: 1; }
    .navbar.bg-light-gray.nav-scroll .navbar-nav {
      background: #f7f7f7; }
    .navbar.bg-light-gray.nav-scroll .navbar-nav .nav-link {
      color: #fff; }
    .navbar.bg-light-gray .navbar-nav .nav-link:hover, .navbar.bg-light-gray.nav-scroll .navbar-nav .nav-link.active {
      color: #232323; }

  /*header bg-light-gray */
  .header {
    transition-timing-function: none;
    -moz-transition-timing-function: none;
    -webkit-transition-timing-function: none;
    -o-transition-timing-function: none;
    -ms-transition-timing-function: none;
    transition-duration: 0s;
    -moz-transition-duration: 0s;
    -webkit-transition-duration: 0s;
    -o-transition-duration: 0s;
    -ms-transition-duration: 0s; }
    .header .caption h2 {
      font-size: 30px; }
    .header .caption h1 {
      font-size: 50px; }
    .header .caption p {
      font-size: 16px; }
    .header .social-links a {
      font-size: 16px; }

  .numbers .item .icon {
    font-size: 32px; }
  .numbers .item p {
    font-size: 14px; }

  .footer-top {
    padding: 50px 0; }

  .footer-social a {
    margin: 0 15px; }
    .footer-social a i {
      font-size: 18px; } }
@media screen and (max-width: 767px) {
  .portfolio .items {
    margin-top: 20px; }

  .header .caption h1 {
    font-size: 32px;
    margin-top: 5px; }
  .header .caption h3 {
    letter-spacing: 1px; }

  .btn {
    padding: 8px 16px;
    font-size: 12px; }

  .blogs .posts .post .content {
    padding: 20px; }
    .blogs .posts .post .content .post-title h5 {
      font-size: 22px; }

  ul.countdown li {
    padding: 0 8px; }

  .coming-soon .social-links li {
    margin-right: 15px; }

  ul.countdown li span {
    font-size: 22px; }

  .contact .info .item .cont h6 {
    font-size: 16px; }

  .footer-social a {
    margin: 0 10px; } }
@media screen and (max-width: 575px) {
  .header .caption h1 {
    font-size: 20px;
    margin-top: 5px; } }

/*# sourceMappingURL=style.css.map */
